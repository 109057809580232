@import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;500;600;700&display=swap');

body{
    font-family: 'Baloo 2', cursive;
    background: #f2f2f2;
}

.flex-container{
  padding-top: 100px;
  max-width: 100%;
}
.flex-container:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  pointer-events: none;
  height: 400px;
  background-color: #ffc40a;
  border-bottom-right-radius: 2.5rem;
}

.brand-logo{
  text-align: center;
  margin-bottom: 15px;
}
.white-card {
  width: 420px;
  max-width: 100%;
  min-height: 300px;
  background: #fff;
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0 0 15px rgb(0 0 0 / 10%);
  position: relative;
  margin: 15px auto;
  font-size: 18px;
}

.white-card video{
  /* width: 100%;
  height: auto; */
  border-radius: 10px;
  /* box-shadow: 0 0 15px rgb(0 0 0 / 10%); */
}

.flex-container {
  margin: 0 15px;
}
.flex-container .inline {
  width: 80px!important;
  margin: 9px 9px 9px 0;
}
.flex-container .small {
  height: 20px!important;
  line-height: 20px!important;
}
.sub-title {
  color: #888;
  font-size: 14px;
  padding: 30px 0 18px 0;
}
.placeholder {
  background-color: #ebebef;
  color: #bbb;
  text-align: center;
  height: 30px;
  line-height: 30px;
  width: 100%;
}


.result .spe {
  width: 60px;
  height: 60px;
}
.result-sub-title {
  color: #252525;
  font-size: 18px;
  text-align: center;
  margin-bottom: 15px;
}

html:not([data-scale]) .am-result::after{
  background: none;
}


@media only screen and (max-width: 574px) {
  .flex-container{
    padding-top: 30px;
  }
  .white-card{
    width: auto;
    padding: 15px;
  }
}